import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { AnalyticsService } from './core/services/analytics.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {

    constructor(private primengConfig: PrimeNGConfig, private renderer: Renderer2, private router: Router, private activatedRoute: ActivatedRoute, private analytics: AnalyticsService) { }

    ngOnInit() {
        // PrimeNG Ripple Effect
        this.primengConfig.ripple = true;
    }

    ngAfterViewInit(): void {

    }
}    
